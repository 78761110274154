import { DefaultApi } from "@client/api";
import { useSuspenseQuery } from "@tanstack/react-query";
import globalAxios from "axios";

import fetchAdapter from "@utils/axiosFetchAdapter";

const tokenPromise = {
  resolve: undefined as undefined | ((callback: () => Promise<string>) => void),
};
const tokenContainer = {
  getAccessTokenCallback: new Promise<() => Promise<string>>((resolve) => {
    tokenPromise.resolve = resolve;
  }),
};

export function setAccessTokenCallback(callback: () => Promise<string>) {
  if (tokenPromise.resolve) {
    tokenPromise.resolve(callback);
  }
}

async function getAccessToken() {
  const callback = await tokenContainer.getAccessTokenCallback;
  const token = await callback();
  return token;
}

// Possible values for 'permission' are:
//   - 'Admin'
//   - 'Process Owner'
//   - 'Control Auditor'
//   - 'Basic User'
// -------------------------
// Possible values for 'field_name' are:
//   - any field name, i.e. "who", "when", "role", "lob", "productOrRelationship", "dataSource"
//   - undefined (as long as user has one of the above permissions present, permission is granted)
// -------------------------
// Possible values for 'level_name' are:
//   - any specifc level value
//   - undefined (as long as user has one of the above permissions present, permission is granted)
export interface Permission {
  permission: string;
  field_name?: string;
  level_name?: string;
}

export function hasPermission(
  acceptedPermissions: Permission[],
  userPermissions: object,
) {
  if (!userPermissions) {
    return false;
  }

  // If user has admin 'all' permission, return true
  if (userPermissions?.["Admin"]?.all) {
    return true;
  }
  for (const acceptedPermission of acceptedPermissions) {
    // Handle when field_name is undefined. As long as the user has the permission, we grant access.
    // An example use case of this is for the create step button. We want anyone who can create a step to be able to see the button
    // and we will further filter what they can create within the create step modal.
    if (!acceptedPermission.field_name) {
      if (
        userPermissions[acceptedPermission.permission] ||
        userPermissions["Admin"]
      ) {
        return true;
      }
    } else {
      const adminPermissions =
        userPermissions["Admin"]?.[acceptedPermission.field_name];
      const fieldPermissions =
        userPermissions[acceptedPermission.permission]?.[
          acceptedPermission.field_name
        ];

      // If user has admin permission for a specific field_name/level_name, always return true
      // OR
      // If user has specified permission for a specific field_name/level_name, return true
      if (
        (adminPermissions &&
          (adminPermissions.has(acceptedPermission.level_name) ||
            adminPermissions.has(acceptedPermission.field_name))) ||
        (fieldPermissions &&
          (fieldPermissions.has(acceptedPermission.level_name) ||
            fieldPermissions.has(acceptedPermission.field_name)))
      ) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Check if the given MIME is a JSON MIME.
 * JSON MIME examples:
 *   application/json
 *   application/json; charset=UTF8
 *   APPLICATION/JSON
 *   application/vnd.company+json
 * @param mime - MIME (Multipurpose Internet Mail Extensions)
 * @return True if the given MIME is JSON, false otherwise.
 */
function isJsonMime(mime: string): boolean {
  const jsonMime: RegExp = new RegExp(
    "^(application/json|[^;/ \\t]+/[^;/ \\t]+[+]json)[ \\t]*(;.*)?$",
    "i",
  );
  return (
    mime !== null &&
    (jsonMime.test(mime) ||
      mime.toLowerCase() === "application/json-patch+json")
  );
}

globalAxios.defaults.adapter = fetchAdapter;

const hostname = window.location.hostname;

let environment = "dev";
if (hostname === "mosaic.magmutual.com") {
  environment = "prod";
} else if (hostname === "mosaic-qa.magmutual.com") {
  environment = "qa";
} else if (hostname === "mosaic-uat.magmutual.com") {
  environment = "uat";
}

const apiClient = new DefaultApi({
  basePath:
    import.meta.env.VITE_PUBLIC_BASE_URL ??
    `https://core-tasks-${environment}.gcp.magmutual.com`,
  accessToken: getAccessToken,
  isJsonMime,
});

export default apiClient;
